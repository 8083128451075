<template>
  <article>
    <div style="
        margin-bottom: 60px;
        font-size: 30px;
        font-weight: 500;
        line-height: 2.67;
        letter-spacing: -0.75px;
      "
    >
      <span class="accent-blue">damda</span> 개발 가이드에서는 다음의 예제를 소개합니다.
    </div>
    <div class="section-intro-desc">
      <ol>
        <li>
          Public 컴포넌트를 활용한 간단한 웹 애플리케이션을 개발 PC에서 개발합니다.
        </li>
        <li>
          개발한 웹 애플리케이션을 damda Console를 통해 클라우드에 컴포넌트로 생성합니다.
        </li>
        <li>클라우드에 생성된 컴포넌트를 기기에 배포합니다.</li>
      </ol>
    </div>
  </article>
</template>

<script>
export default {};
</script>

<style></style>
